var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"opacity":"0.17","blur":"1rem","show":_vm.showOverlay,"rounded":"md","variant":"secondary"}},[_c('div',{staticClass:"card",staticStyle:{"box-shadow":"none !important"}},[_c('div',{staticClass:"card-header border-bottom d-flex"},[_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-start",staticStyle:{"width":"70%"}},[(_vm.componentMode == 'EDIT')?[_c('h4',{staticClass:"font-weight-bolder"},[_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.headerData.title))])]),_c('p',{staticClass:"card-subtitle text-muted pt-75"},[_vm._v(" "+_vm._s(_vm.headerData.description)+" ")])]:_vm._e(),[(_vm.componentMode !== 'EDIT')?_c('h4',{staticClass:"font-weight-bolder"},[_c('span',{staticClass:"align-middle"},[_vm._v("Risk Details")])]):_vm._e()]],2),_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){return _vm.closeSidebar()}}},[_c('feather-icon',{attrs:{"size":"24","icon":"XIcon"}})],1)],1)]),(_vm.componentMode == 'VIEW')?_c('div',{staticClass:"w-100 p-1 pb-0"},[_c('b-button',{staticClass:"float-right",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.handleEditButtonClick()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Edit")])],1)],1):_vm._e(),(_vm.componentMode != 'VIEW')?_c('div',{staticClass:"card-body pt-3"},[_c('validation-observer',{ref:"risk_assessment_form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return (function () {
              _vm.componentMode == 'EDIT'
                ? _vm.handleResponseUpdateClick()
                : _vm.handleSubmitClick();
            }).apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Risk"}},[_c('template',{slot:"label"},[_vm._v(" Risk "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"vid":"risk_name","name":"Risk","rules":{
                required: true,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('vue-autosuggest',{attrs:{"id":"autosuggest__input","input-props":{
                  class: 'form-control',
                  placeholder: 'Risk Name...',
                },"suggestions":_vm.riskSuggestions,"limit":5,"get-suggestion-value":_vm.getRiskSuggestionValue},on:{"input":_vm.getRisksAndSetSuggestions,"selected":_vm.onRiskSelected},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var suggestion = ref.suggestion;
return [_c('span',{staticStyle:{"{ display":"'flex', color: 'navyblue'}"}},[_vm._v(_vm._s(suggestion.item.name)+" ")])]}}],null,true),model:{value:(_vm.formData.risk.name),callback:function ($$v) {_vm.$set(_vm.formData.risk, "name", $$v)},expression:"formData.risk.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),(
              _vm.formData.risk.selected === null ||
              _vm.formData.risk.selected.added_by == _vm.$store.state.app.user.user_id
            )?_c('b-form-group',{attrs:{"label":"Threat"}},[_c('template',{slot:"label"},[_vm._v(" Threat "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"d-flex flex-row justify-content-between align-items-center"},[_c('validation-provider',{staticStyle:{"width":"60%"},attrs:{"vid":"threat_id","name":"Threat","rules":{
                  required: true,
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('vue-autosuggest',{attrs:{"id":"autosuggest__input","input-props":{
                    class: 'form-control',
                    placeholder: 'Threat Name...',
                  },"suggestions":_vm.threatSuggestions,"limit":5,"get-suggestion-value":_vm.getThreatSuggestionValue},on:{"input":_vm.getThreatsAndSetSuggestions,"selected":_vm.onThreatSelected},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var suggestion = ref.suggestion;
return [_c('span',{staticStyle:{"{ display":"'flex', color: 'navyblue'}"}},[_vm._v(_vm._s(suggestion.item.name)+" ")])]}}],null,true),model:{value:(_vm.formData.threat.name),callback:function ($$v) {_vm.$set(_vm.formData.threat, "name", $$v)},expression:"formData.threat.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"d-flex align-items-center justify-content-center",staticStyle:{"width":"36%"}},[_c('validation-provider',{staticClass:"w-100",attrs:{"vid":"threat_value","name":"Threat Value","rules":{
                    required: _vm.formData.threat.name !== null ? true : false,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.vulnerabilityOptions},model:{value:(_vm.formData.threat.value),callback:function ($$v) {_vm.$set(_vm.formData.threat, "value", $$v)},expression:"formData.threat.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],2):_vm._e(),(
              _vm.formData.risk.selected === null ||
              _vm.formData.risk.selected.added_by == _vm.$store.state.app.user.user_id
            )?_c('b-form-group',{attrs:{"label":"Vulnerabilities"}},[_c('template',{slot:"label"},[_vm._v(" Vulnerabilities "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Vulnerabilities","vid":"vulnerabilities","rules":{
                required: true,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('div',{staticClass:"card border w-100",staticStyle:{"min-height":"230px"}},[(_vm.formData.vulnerabilities.addedList.length <= 0)?_c('div',{staticClass:"card-body d-flex flex-column align-items-start justify-content-around"},[_c('div',{staticClass:"d-flex justify-content-center align-items-center w-100",staticStyle:{"height":"150px"}},[_c('p',{staticClass:"mb-0 text-center"},[_vm._v(" There are no vulnerabilities, Please add Vulnerability"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])])]):_c('div',{staticClass:"card-body d-flex flex-column align-items-center justify-content-start px-3 py-1",staticStyle:{"min-height":"200px"}},_vm._l((_vm.formData.vulnerabilities
                      .addedList),function(vulnerability,i){return _c('div',{key:i,staticClass:"w-100 d-flex align-items-center justify-content-between my-75"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center",staticStyle:{"width":"40%"}},[_c('vue-autosuggest',{staticStyle:{"width":"100%"},attrs:{"id":"autosuggest__input","input-props":{
                          class: 'form-control',
                          placeholder: 'Search & Select Vulnerabilities...',
                        },"suggestions":_vm.vulnerabilitySuggestions,"limit":5,"get-suggestion-value":_vm.getVulnerabilitySuggestionValue},on:{"input":_vm.getVulnerabilitiesAndSetSuggestions,"selected":function (vul) {
                            _vm.onVulnerabilitySelected(vul, i);
                          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var suggestion = ref.suggestion;
return [_c('span',{staticStyle:{"{ display":"'flex', color: 'navyblue'}"}},[_vm._v(_vm._s(suggestion.item.name)+" ")])]}}],null,true),model:{value:(vulnerability.name),callback:function ($$v) {_vm.$set(vulnerability, "name", $$v)},expression:"vulnerability.name"}})],1),_c('div',{staticClass:"d-flex align-items-center justify-content-center",staticStyle:{"width":"40%"}},[_c('b-form-select',{attrs:{"options":_vm.vulnerabilityOptions},model:{value:(vulnerability.value),callback:function ($$v) {_vm.$set(vulnerability, "value", $$v)},expression:"vulnerability.value"}})],1),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.removeSelectedVulnerability(i)}}},[_c('feather-icon',{staticClass:"text-white",attrs:{"icon":"XIcon"}})],1)],1)])}),0),_c('div',{staticClass:"card-footer bg-light-secondary d-flex align-items-center justify-content-start"},[_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.onAddVulnerabilityClick()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Add New")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],2):_vm._e(),_c('b-form-group',{attrs:{"label":"Statement"}},[_c('template',{slot:"label"},[_vm._v(" Statement "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"vid":"risk_statement","name":"Risk Statement","rules":{
                required: false,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"risk_statement","state":errors.length > 0 ? false : null,"rows":"3","max-rows":"4"},model:{value:(_vm.formData.riskStatement),callback:function ($$v) {_vm.$set(_vm.formData, "riskStatement", $$v)},expression:"formData.riskStatement"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),_c('b-form-group',{attrs:{"label":"Impact Score"}},[_c('template',{slot:"label"},[_vm._v(" Impact Score "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"vid":"impact_score","name":"Impact Score","rules":{
                required: true,
                positive: true,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.impactOptions},on:{"change":_vm.handleImpactSelect},model:{value:(_vm.formData.impactValue),callback:function ($$v) {_vm.$set(_vm.formData, "impactValue", $$v)},expression:"formData.impactValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),_c('b-form-group',{attrs:{"label":"Likelihood"}},[_c('template',{slot:"label"},[_vm._v(" Likelihood "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"vid":"likelihood_value","name":"Likelihood","rules":{
                required: true,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.likelihoodOptions},on:{"change":_vm.handleLikelihoodSelect},model:{value:(_vm.formData.likelihoodValue),callback:function ($$v) {_vm.$set(_vm.formData, "likelihoodValue", $$v)},expression:"formData.likelihoodValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),_c('b-form-group',{attrs:{"label":"Residual Impact Score"}},[_c('template',{slot:"label"},[_vm._v(" Residual Impact Score "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"vid":"residual_impact_value","name":"Residual Impact Score","rules":{
                required: true,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.impactOptions},model:{value:(_vm.formData.residualImpactValue),callback:function ($$v) {_vm.$set(_vm.formData, "residualImpactValue", $$v)},expression:"formData.residualImpactValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),_c('b-form-group',{attrs:{"label":"Residual Likelihood"}},[_c('template',{slot:"label"},[_vm._v(" Residual Likelihood "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"vid":"residual_likelihood_value","name":"Residual Likelihood","rules":{
                required: true,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.likelihoodOptions},model:{value:(_vm.formData.residualLikelihoodValue),callback:function ($$v) {_vm.$set(_vm.formData, "residualLikelihoodValue", $$v)},expression:"formData.residualLikelihoodValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),(_vm.componentMode == 'NEW')?_c('b-button',{staticClass:"mr-1 mt-1",attrs:{"type":"submit","variant":"primary","disabled":invalid}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Submit")])],1):_c('b-button',{staticClass:"mr-1 mt-1",attrs:{"variant":"success","type":"submit","disabled":invalid}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Update")])],1)],1)]}}],null,false,3104803385)})],1):_c('response-assessment-details-view',{attrs:{"closeSidebar":_vm.closeSidebar,"response":_vm.prefetchedFormData,"assessmentId":_vm.riskAssessmentId}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }