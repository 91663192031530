<template>
  <div
    class="card-body pt-1 w-100 d-flex flex-column align-items-start justify-content-start"
  >
    <div
      class="d-flex flex-column align-items-start justify-content-start mb-1 w-100"
    >
      <h6 class="text-primary">Name</h6>
      <div
        class="rounded border d-flex align-items-start justify-content-start w-100 p-1"
      >
        <div class="d-flex align-items-start justify-content-start w-100">
          <p class="text-left font-weight-bold mb-0">
            {{ responseData.risk.name }}
          </p>
        </div>
      </div>
    </div>

    <b-card align="center" class="bg-dark-blue h-100 w-100 mb-1">
      <div class="row">
        <div class="col">
          <b-list-group class="list-group-circle text-left bg-transparent">
            <b-list-group-item
              class="bg-transparent"
              v-if="responseData.risk_value && responseData.risk_value !== null"
            >
              <div class="d-flex align-items-center justify-content-start">
                <b>Risk Score:</b>
                <span
                  class="ml-1 d-flex align-items-center justify-content-start"
                >
                  <!-- <progress-badge
                    :value="responseData.risk_value"
                    :min="0"
                    :max="100"
                    title="Risk Score"
                  /> -->
                  <b-badge
                    v-if="
                      responseData.risk_value &&
                      responseData.risk_value != null &&
                      responseData.risk_value != ''
                    "
                    style="width: 120px"
                    :variant="getMappedVariant(responseData.risk_value)"
                    >{{ getMappedTextValue(responseData.risk_value) }}
                    <b-badge pill class="ml-25" size="sm">{{
                      responseData.risk_value
                    }}</b-badge></b-badge
                  >
                </span>
              </div>
            </b-list-group-item>

            <b-list-group-item class="bg-transparent">
              <b>Likelihood:</b>
              <span class="ml-50">
                <b-badge
                  v-if="
                    responseData.likelihood_value &&
                    responseData.likelihood_value !== null
                  "
                  variant="light-primary"
                  >{{
                    getLikelihoodTextFromValue(responseData.likelihood_value)
                  }}</b-badge
                ></span
              >
            </b-list-group-item>

            <b-list-group-item
              class="bg-transparent"
              v-if="
                responseData.impact_score && responseData.impact_score !== null
              "
            >
              <b>Impact:</b>
              <span class="ml-50">
                <b-badge
                  style="width: 120px"
                  :variant="getVariant(responseData.impact_score)"
                  >{{ getTextValue(responseData.impact_score) }}
                  <b-badge pill class="ml-25" size="sm">{{
                    responseData.impact_score
                  }}</b-badge></b-badge
                ></span
              >
            </b-list-group-item>
          </b-list-group>
        </div>
        <div class="col">
          <b-list-group class="list-group-circle text-left bg-transparent">
            <b-list-group-item
              class="bg-transparent"
              v-if="
                responseData.residual_risk_value &&
                responseData.residual_risk_value !== null
              "
            >
              <div class="d-flex align-items-center justify-content-start">
                <b>Residual Risk Score:</b>
                <span
                  class="ml-1 d-flex align-items-center justify-content-start"
                >
                  <!-- <progress-badge
                    :value="responseData.residual_risk_value"
                    :min="0"
                    :max="100"
                    title="Residaul risk Score"
                  /> -->
                  <b-badge
                    v-if="
                      responseData.residual_risk_value &&
                      responseData.residual_risk_value != null &&
                      responseData.residual_risk_value != ''
                    "
                    style="width: 120px"
                    :variant="getMappedVariant(responseData.residual_risk_value)"
                    >{{ getMappedTextValue(responseData.residual_risk_value) }}
                    <b-badge pill class="ml-25" size="sm">{{
                      responseData.residual_risk_value
                    }}</b-badge></b-badge
                  >
                </span>
              </div>
            </b-list-group-item>

            <b-list-group-item class="bg-transparent">
              <b>Residual Likelihood:</b>
              <span class="ml-50">
                <b-badge
                  v-if="
                    responseData.residual_likelihood_value &&
                    responseData.residual_likelihood_value !== null
                  "
                  variant="light-primary"
                  >{{
                    getLikelihoodTextFromValue(
                      responseData.residual_likelihood_value
                    )
                  }}</b-badge
                ></span
              >
            </b-list-group-item>

            <b-list-group-item
              class="bg-transparent"
              v-if="
                responseData.residual_impact_score &&
                responseData.residual_impact_score !== null
              "
            >
              <b>Residual Impact:</b>
              <span class="ml-50">
                <b-badge
                  style="width: 120px"
                  :variant="getVariant(responseData.residual_impact_score)"
                  >{{ getTextValue(responseData.residual_impact_score) }}
                  <b-badge pill class="ml-25" size="sm">{{
                    responseData.residual_impact_score
                  }}</b-badge></b-badge
                ></span
              >
            </b-list-group-item>
          </b-list-group>
        </div>
      </div>
    </b-card>

    <div
      class="d-flex flex-column align-items-start justify-content-start mb-1 w-100"
      v-if="responseData.risk_statement && responseData.risk_statement !== null"
    >
      <h6 class="text-primary">Statement</h6>
      <div
        class="rounded border d-flex align-items-start justify-content-start w-100 p-1"
      >
        <div class="d-flex align-items-start justify-content-start w-100">
          <p class="text-left font-weight-bold mb-0">
            {{ responseData.risk_statement }}
          </p>
        </div>
      </div>
    </div>

    <!-- <div
      class="d-flex flex-column align-items-start justify-content-start mb-2 w-100"
      v-if="responseData.risk_statement && responseData.risk_statement !== null"
    >
      <h6>Statement</h6>
      <div class="rounded d-flex align-items-start justify-content-start w-100">
        <div class="d-flex align-items-start justify-content-start">
          <h5 class="text-left font-weight-bold mb-0">
            {{ responseData.risk_statement }}
          </h5>
        </div>
      </div>
    </div> -->

    <div
      class="d-flex flex-column align-items-start justify-content-between mb-2 w-100"
    >
      <h6 class="text-primary">Threats</h6>
      <div
        class="rounded border d-flex align-items-center justify-content-between w-100"
      >
        <table role="table" class="table table-sm">
          <thead role="rowgroup">
            <tr role="row">
              <th role="columnheader" scope="col"></th>
              <th role="columnheader" scope="col">Name</th>
              <th role="columnheader" scope="col" class="text-center">Value</th>
            </tr>
          </thead>
          <tbody role="rowgroup">
            <tr role="row">
              <td role="cell">1</td>
              <td role="cell">
                {{ responseData.risk.threat.name }}
              </td>
              <td role="cell">
                <div
                  class="d-flex align-items-center justify-content-center w-100"
                >
                  <b-badge
                    style="width: 120px"
                    :variant="getVariant(responseData.risk.threat.threat_value)"
                    >{{ getTextValue(responseData.risk.threat.threat_value) }}
                    <b-badge pill class="ml-25" size="sm">{{
                      responseData.risk.threat.threat_value
                    }}</b-badge></b-badge
                  >
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- <div
      class="d-flex flex-column align-items-start justify-content-start mb-2 w-100"
      style="width: 100%"
    >
      <h6 class="mb-50">Threat</h6>
      <div
        class="d-flex align-items-center justify-content-between"
        style="width: 100%"
      >
        <div
          class="rounded border d-flex align-items-start justify-content-start p-50"
          style="width: 100%"
        >
          <div class="d-flex align-items-center justify-content-between w-100">
            <div
              class="w-auto h-100 d-flex align-items-center justify-content-center"
            >
              <p class="text-left font-weight-bold mb-0">
                {{ responseData.risk.threat.name }}
              </p>
            </div>
            <div
              class="d-flex align-items-center justify-content-center"
              style="width: 20%"
            >
              <value-badge :value="responseData.risk.threat.threat_value" />
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div
      class="d-flex flex-column align-items-start justify-content-between mb-2 w-100"
    >
      <h6 class="text-primary">Vulnerabilities</h6>
      <div
        class="rounded border d-flex align-items-center justify-content-between w-100"
      >
        <table role="table" class="table table-sm">
          <thead role="rowgroup">
            <tr role="row">
              <th role="columnheader" scope="col"></th>
              <th role="columnheader" scope="col">Name</th>
              <th role="columnheader" scope="col" class="text-center">Value</th>
            </tr>
          </thead>
          <tbody role="rowgroup">
            <tr
              role="row"
              :key="i"
              v-for="(vuln, i) in responseData.risk.vulnerabilities"
            >
              <td role="cell">
                {{ i + 1 }}
              </td>
              <td role="cell">
                {{ vuln.name }}
              </td>
              <td role="cell">
                <div
                  class="d-flex align-items-center justify-content-center w-100"
                >
                  <b-badge
                    style="width: 120px"
                    :variant="getVariant(vuln.vulnerability_value)"
                    >{{ getTextValue(vuln.vulnerability_value) }}
                    <b-badge pill class="ml-25" size="sm">{{
                      vuln.vulnerability_value
                    }}</b-badge></b-badge
                  >
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- 
    <div
      class="d-flex flex-column align-items-start justify-content-start mb-2 w-100"
    >
      <h6 class="mb-50">Vulnerability</h6>
      <div class="w-100 d-flex align-items-center justify-content-between">
        <div
          class="rounded border d-flex flex-column align-items-center justify-content-around p-50 w-100"
        >
          <div
            class="d-flex align-items-center justify-content-between w-100 py-25"
            :key="vulnerability._id"
            v-for="vulnerability in responseData.risk.vulnerabilities"
          >
            <div
              class="w-auto d-flex align-items-center justify-content-center"
            >
              <p class="font-weight-bold mb-0">
                {{ vulnerability.name }}
              </p>
            </div>
            <div
              class="d-flex align-items-center justify-content-center"
              style="width: 20%"
            >
              <value-badge :value="vulnerability.vulnerability_value" />
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="row mt-1">
      <!-- <div
        class="d-flex flex-column align-items-center justify-content-start ml-1 mr-1"
        style="width: 100%"
        v-if="
          responseData.residual_risk_value &&
          responseData.residual_risk_value !== null &&
          responseData.risk_value &&
          responseData.risk_value !== null
        "
      >
        <div
          class="d-flex flex-row align-items-center justify-content-between rounded pl-50 pr-50 border w-100"
        >
          <h6 class="mb-50 text-primary"><b>Risk</b></h6>
          <div class="d-flex">
            <div
              class="d-flex flex-column align-items-start justify-content-start mb-1 mr-1"
              v-if="responseData.risk_value && responseData.risk_value !== null"
            >
              <p class="font-weight-bold mb-25" style="font-size: 12px">
                Score
              </p>
              <progress-badge
                :value="responseData.risk_value"
                :min="0"
                :max="100"
                title="Risk Score"
              />
            </div>

            <div
              class="d-flex flex-column align-items-start justify-content-start"
              v-if="
                responseData.residual_risk_value &&
                responseData.residual_risk_value !== null
              "
            >
              <p class="font-weight-bold mb-25" style="font-size: 12px">
                Residual Score
              </p>
              <progress-badge
                :value="responseData.residual_risk_value"
                :min="0"
                :max="100"
                title="Residaul isk Score"
              />
            </div>
          </div>
        </div>
      </div> -->

      <!-- <div
        class="row pl-1 mt-1 mb-1"
        style="width: 100%"
        v-if="
          responseData.residual_impact_score &&
          responseData.residual_impact_score !== null &&
          responseData.impact_score &&
          responseData.impact_score !== null
        "
      >
        <div
          class="d-flex flex-row align-items-center justify-content-between rounded border w-100 ml-1 p-50"
        >
          <h6 class="mb-50 text-primary"><b>Impact</b></h6>
          <div class="d-flex">
            <div
              class="d-flex flex-column align-items-start justify-content-start mr-1"
              v-if="
                responseData.impact_score && responseData.impact_score !== null
              "
            >
              <value-badge :value="responseData.impact_score" />
            </div>

            <div
              class="d-flex flex-column align-items-start justify-content-start"
              v-if="
                responseData.residual_impact_score &&
                responseData.residual_impact_score !== null
              "
            >
              <value-badge :value="responseData.residual_impact_score" />
            </div>
          </div>
        </div>
      </div> -->

      <!-- <div
        class="row pl-1"
        style="width: 100%"
        v-if="
          responseData.residual_impact_score &&
          responseData.residual_impact_score !== null &&
          responseData.impact_score &&
          responseData.impact_score !== null
        "
      >
        <div
          class="d-flex flex-row align-items-center justify-content-between rounded p-50 border w-100 ml-1"
        >
          <h6 class="mb-50 text-primary"><b>Likelihood</b></h6>
          <div class="d-flex">
            <div
              class="d-flex flex-column align-items-start justify-content-start mb-1 mr-1"
              v-if="
                responseData.likelihood_value &&
                responseData.likelihood_value !== null
              "
            >
              <p class="font-weight-bold mb-0" style="font-size: 12px">Value</p>
              <b-badge variant="light-primary">{{
                getLikelihoodTextFromValue(responseData.likelihood_value)
              }}</b-badge>
            </div>

            <div
              class="d-flex flex-column align-items-start justify-content-start"
              v-if="
                responseData.residual_likelihood_value &&
                responseData.residual_likelihood_value !== null
              "
            >
              <p class="font-weight-bold mb-0" style="font-size: 12px">
                Residual
              </p>
              <b-badge variant="light-primary">{{
                getLikelihoodTextFromValue(
                  responseData.residual_likelihood_value
                )
              }}</b-badge>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <!-- <div
      class="
        d-flex
        flex-column
        align-items-center
        justify-content-between
        bg-light-secondary
        rounded
        px-1
        py-2
        w-100
        mb-1
      "
    >
      <div class="d-flex align-items-center justify-content-around w-100">
        <div
          class="d-flex flex-column align-items-center justify-content-start"
        >
          <h6>Risk Score</h6>
          <div class="d-flex align-items-center justify-content-center">
            <donut-bar
              :displayValue="data.risk_value"
              :percentage="percentage(data.risk_value, 100)"
              size="sm"
              :uniqueId="data.risk._id"
            />
          </div>
        </div>

        <div
          class="d-flex flex-column align-items-center justify-content-start"
        >
          <h6>Residual Risk Score</h6>
          <div
            class="d-flex align-items-center justify-content-center"
            v-if="data.residual_risk_value"
          >
            <donut-bar
              :displayValue="data.residual_risk_value"
              :percentage="percentage(data.residual_risk_value, 100)"
              size="sm"
              :uniqueId="data.risk._id + 'residual'"
            />
          </div>
        </div>
      </div>

      <div class="mt-1 d-flex align-items-center justify-content-around w-100">
        <div
          class="d-flex flex-column align-items-center justify-content-start"
        >
          <h6>Impact Value</h6>
          <div class="d-flex align-items-center justify-content-center">
            <donut-bar
              :displayValue="data.impact_value"
              :percentage="percentage(data.impact_value, 100)"
              size="sm"
              :uniqueId="impact_value + 'impact_value'"
            />
          </div>
        </div>

        <div
          class="d-flex flex-column align-items-center justify-content-start"
        >
          <h6>Residual Impact Value</h6>
          <div
            class="d-flex align-items-center justify-content-center"
            v-if="data.residual_impact_value"
          >
            <donut-bar
              :displayValue="data.residual_impact_value"
              :percentage="percentage(data.residual_impact_value, 100)"
              size="sm"
              :uniqueId="data.residual_impact_value + 'residual'"
            />
          </div>
        </div>
      </div>

      <div
        class="
          d-flex
          flex-row
          align-items-center
          justify-content-around
          mt-1
          w-100
        "
      >
        <div
          class="
            rounded
            p-1
            bg-light-primary
            d-flex
            flex-column
            align-items-center
            justify-content-start
          "
        >
          <p class="text-primary text-center font-weight-bolder mb-50">
            Likelihood
          </p>
          <b-badge variant="light-primary">{{
            getLikelihoodTextFromValue(data.likelihood_value)
          }}</b-badge>
        </div>

        <div
          class="
            rounded
            p-1
            bg-light-primary
            d-flex
            flex-column
            align-items-center
            justify-content-start
          "
        >
          <p class="text-primary text-center font-weight-bolder mb-50">
            Residual Likelihood
          </p>
          <b-badge variant="light-primary">{{
            getLikelihoodTextFromValue(data.residual_likelihood_value)
          }}</b-badge>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import {
  BOverlay,
  BButton,
  BBadge,
  BCard,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import ProgressBar from "@/components/ProgressBar.vue";
import DonutBar from "@/components/DonutBar.vue";
import ScoreInput from "@/components/ScoreInput.vue";
import ScoreBadge from "@/components/ScoreBadge.vue";
import ValueBadge from "@/components/ValueBadge.vue";
import ProgressBadge from "@/components/ProgressBadge.vue";
import RiskAssessmentMixins from "@/mixins/RiskAssessmentMixins";
import ResponseMixins from "@/mixins/ResponseMixins";

export default {
  components: {
    BOverlay,
    BButton,
    BBadge,
    ProgressBar,
    DonutBar,
    ScoreInput,
    ScoreBadge,
    ValueBadge,
    ProgressBadge,
    BCard,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      showOverlay: false,
      responseData: null,
    };
  },

  props: {
    closeSidebar: {
      required: true,
      type: Function,
    },
    response: {
      required: false,
      type: Object,
    },
    assessmentId: {
      required: false,
      type: String,
    },
  },

  mounted() {
    this.load();
  },
  mixins: [RiskAssessmentMixins, ResponseMixins],

  methods: {
    load() {
      console.log("Assessment Id", this.assessmentId);
      console.log("Response", this.response);
      this.getRiskAssessmentResponseDetailsAndSetData(
        this.assessmentId,
        this.response._id
      );
    },

     getMappedTextValue(value) {
      const status = Math.floor(this.mapValue(value));
      const newValue = Number(status);
      console.log(newValue);
      switch (newValue) {
        case 1:
          return "Very Low";
        case 2:
          return "Low";
        case 3:
          return "Medium";
        case 4:
          return "High";
        case 5:
          return "Very High";
        default:
          return "Very Low";
      }
    },

    getMappedVariant(value) {
      const status = Math.floor(this.mapValue(value));
      const newValue = Number(status);
      console.log(newValue);
      switch (newValue) {
        case 1:
          return "light-success";
        case 2:
          return "light-info";
        case 3:
          return "light-primary";
        case 4:
          return "light-warning";
        case 5:
          return "light-danger";
        default:
          return "light-success";
      }
    },

    mapValue(value) {
      return this.mapRange(value, 0, 100, 1, 5);
    },

    mapRange(value, inMin, inMax, outMin, outMax) {
      return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
    },

    getVariant(status) {
      const newValue = Number(status);
      switch (newValue) {
        case 1:
          return "light-success";
        case 2:
          return "light-info";
        case 3:
          return "light-primary";
        case 4:
          return "light-warning";
        case 5:
          return "light-danger";
        default:
          return "light-success";
      }
    },

    getTextValue(status) {
      const newValue = Number(status);
      switch (newValue) {
        case 1:
          return "Very Low";
        case 2:
          return "Low";
        case 3:
          return "Medium";
        case 4:
          return "High";
        case 5:
          return "Very High";
        default:
          return "Very Low";
      }
    },

    getRiskAssessmentResponseDetailsAndSetData(assessmentId, responseId) {
      this.showOverlay = true;
      this.getRiskAssessmentResponseDetails(assessmentId, responseId)
        .then((res) => {
          console.log("ResponseDetails", res);
          this.responseData = res.data.data;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    percentage(partialValue, totalValue) {
      return Math.round(((100 * partialValue) / totalValue) * 10) / 10;
    },

    mapColorToValue(value) {
      if (value <= 11) {
        return "#28C76F";
      } else if (value > 11 && value <= 17) {
        return "#00CFE8";
      } else if (value > 17 && value <= 35) {
        return "#7267f0";
      } else if (value > 35 && value <= 74) {
        return "#FF9F43";
      } else if (value > 74 && value <= 100) {
        return "#EA5455";
      } else {
        return "#28C76F";
      }
    },

    mapTextClassToValue(value) {
      if (value <= 11) {
        return "text-success";
      } else if (value > 11 && value <= 17) {
        return "text-info";
      } else if (value > 17 && value <= 35) {
        return "text-primary";
      } else if (value > 35 && value <= 74) {
        return "text-warning";
      } else if (value > 74 && value <= 100) {
        return "text-danger";
      } else {
        return "text-success";
      }
    },

    mapValueName(value) {
      if (value <= 11) {
        return "Very Low";
      } else if (value > 11 && value <= 17) {
        return "Low";
      } else if (value > 17 && value <= 35) {
        return "Medium";
      } else if (value > 35 && value <= 74) {
        return "High";
      } else if (value > 74 && value <= 100) {
        return "Very High";
      } else {
        return "Very Low";
      }
    },

    getLikelihoodTextFromValue(value) {
      if (value && value !== null) {
        if (value == 1) {
          return "Once in 5 years";
        } else if (value == 2) {
          return "Once in 2 years";
        } else if (value == 3) {
          return "Once a year";
        } else if (value == 4) {
          return "Once in Six months";
        } else if (value == 5) {
          return "Once in a month";
        } else {
          console.log("Unknown Value", value);
          return "Unknown Value";
        }
      }
    },
  },
};
</script>

<style>
</style>
