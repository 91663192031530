<template>
  <div class="d-flex flex-row align-items-center justify-content-between p-50 rounded" :class="getBGLightVariant(value)">
    <p class="font-weight-bolder mr-50 mb-0" :class="getTextVariant(value)">{{ mapScoreToTextValue(value) }}</p>
    <b-badge pill :variant="getVariant(value)">{{value}}</b-badge>
  </div>
</template>

<script>
import { BAvatar, BButton, VBHover, BBadge, } from "bootstrap-vue";
export default {
  components: {
    BAvatar,
    BButton,
    BBadge,
  },
  name: "ScoreInput",
  directives: { "v-b-hover": VBHover },
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isHovering: false,
    };
  },
  computed: {
    mapScoreToTextValue() {
      return (val) => {
        if (val && val > 1 && val <= 5) {
          if (val == 2) {
            return "Low";
          }
          if (val == 3) {
            return "Medium";
          }
          if (val == 4) {
            return "High";
          }
          if (val == 5) {
            return "Critical";
          }
        } else {
          return "Very Low";
        }
      };
    },
  },
  methods: {
    getVariant(status) {
      switch (status) {
        case 1:
          return "success";
        case 2:
          return "info";
        case 3:
          return "primary";
        case 4:
          return "warning";
        case 5:
          return "danger";
        default:
          return "success";
      }
    },
    getFlatVariant(status) {
      switch (status) {
        case 1:
          return "flat-success";
        case 2:
          return "flat-info";
        case 3:
          return "flat-primary";
        case 4:
          return "flat-warning";
        case 5:
          return "flat-danger";
        default:
          return "flat-success";
      }
    },

     getBGLightVariant(status) {
      switch (status) {
        case 1:
          return "bg-light-success";
        case 2:
          return "bg-light-info";
        case 3:
          return "bg-light-primary";
        case 4:
          return "bg-light-warning";
        case 5:
          return "bg-light-danger";
        default:
          return "bg-light-success";
      }
    },
    getHex(status) {
      switch (status) {
        case 1:
          return "#00cfe8";
        case 2:
          return "#28c76f";
        case 3:
          return "#ff9f43";
        case 4:
          return "#ea5455";
        case 5:
          return "#7367f0";
        default:
          return "#00cfe8";
      }
    },
    getScoreValueFromNumber(num) {
      switch (num) {
        case 1:
          return "Low";
        case 2:
          return "Medium";
        case 3:
          return "High";
        case 4:
          return "Very High";
        default:
          return "Very Low";
      }
    },
    getTextVariant(status) {
      switch (status) {
        case 1:
          return "text-success";
        case 2:
          return "text-info";
        case 3:
          return "text-primary";
        case 4:
          return "text-warning";
        case 5:
          return "text-danger";
        default:
          return "text-success";
      }
    },
  },
};
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>