<template>
  <div>
    <b-sidebar
      lazy
      id="sidebar-backdrop"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      width="60rem"
    >
      <risk-asessment-form
        :closeSidebar="closeSidebar"
        :componentMode="sidebarMode"
        :setComponentMode="setSidebarViewMode"
        :prefetchedFormData="selectedResponse"
        :response="selectedResponse"
        :riskAssessmentId="$route.params.id"
        :headerData="sidebar"
        :reloadParent="load"
      />
    </b-sidebar>

    <b-sidebar
      lazy
      id="sidebar-assessment-details"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      width="580px"
    >
      <response-assessment-details-view
        :closeSidebar="closeDetailsSidebar"
        :response="selectedResponse"
        :assessmentId="$route.params.id"
      />
    </b-sidebar>
    <div class="card" v-if="assessment !== null">
      
      <div
        class="card-header d-flex flex-row align-items-center justify-content-start"
      >
        <div
          class="w-50 d-flex flex-column align-items-start justify-content-center"
        >
          <h2 class="font-weight-bolder pb-25">
            {{ assessment.title }}
          </h2>
          <p class="font-weight-bold">{{ assessment.notes }}</p>
        </div>

        <div
          style="width: 400px"
          class="d-flex flex-column align-items-start justify-content-center rounded bg-light-secondary mx-auto"
        >
          <table class="table table-borderless">
            <tbody>
              <tr>
                <td style="padding-right: 0; width: 200px">
                  <p class="font-weight-bold mb-0">Assessed User:</p>
                </td>
                <td style="padding-right: 0">
                  <p
                    class="font-weight-bolder mb-0"
                    v-if="
                      assessment.assessed_user &&
                      assessment.assessed_user != null
                    "
                  >
                    {{ assessment.assessed_user.firstname }}
                    {{ assessment.assessed_user.lastname }}
                  </p>
                  <b-badge v-else variant="danger">Deleted User</b-badge>
                </td>
              </tr>
              <tr>
                <td style="padding-right: 0; width: 200px">
                  <p class="font-weight-bold mb-0">Created At:</p>
                </td>
                <td style="padding-right: 0">
                  <p class="font-weight-bolder mb-0">
                    {{ assessment.created_at | moment }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-if="responses !== null" class="card">
      <div class="card-header">
        <b-button variant="success" @click="handleCreateNewRiskClick"
          ><feather-icon icon="PlusIcon" class="mr-75" />
          <span class="text-center">Add New Risk</span>
        </b-button>
      </div>
      <div class="card-body">
        <!-- <div class="table-responsive" style="max-height: 70vh"> -->
        <table role="table" class="table table-hover">
          <thead role="rowgroup">
            <tr role="row">
              <th role="columnheader" class="header" scope="col">Risk</th>
              <th role="columnheader" class="header" scope="col">Statement</th>
              <th role="columnheader" scope="col" class="header text-center">
                Risk Value
              </th>
              <!-- <th role="columnheader" class="header" scope="col">Threat</th> -->
              <!-- <th role="columnheader" scope="col" class="header text-center">
                  Threat Value
                </th> -->
              <!-- <th role="columnheader" class="header" scope="col">
                  Vulnerabilities
                </th> -->
              <!-- <th role="columnheader" scope="col" class="header text-center">
                  Vulnerability Value
                </th> -->
              <!-- <th role="columnheader" scope="col" class="header text-center">
                  Impact
                </th> -->
              <!-- <th role="columnheader" scope="col" class="header text-center">
                  Likelihood
                </th> -->
              <th role="columnheader" scope="col" class="header text-center">
                Residual Risk
              </th>
              <!-- <th role="columnheader" scope="col" class="header text-center">
                  Residual Threat
                </th> -->
              <!-- <th role="columnheader" scope="col" class="header text-center">
                  Residual Vulnerability
                </th> -->
              <!-- <th role="columnheader" scope="col" class="header text-center">
                  Residual Impact
                </th> -->
              <!-- <th role="columnheader" scope="col" class="header text-center">
                  Residual Likelihood
                </th> -->
              <th role="columnheader" scope="col" class="header text-center">
                Actions
              </th>
            </tr>
          </thead>
          <tbody role="rowgroup">
            <tr
              v-for="response in responses"
              :key="response._id"
              role="row"
              class="cursor-pointer"
              @click="handleAssessmentDetailsView(response)"
            >
              <td aria-colindex="2" role="cell" style="width: 200px">
                <div
                  style="width: 200px"
                  class="d-flex justify-content-start"
                  v-b-tooltip.hover.top.v-secondary
                  :title="response.risk.name"
                >
                  <p class="text-left font-weight-bolder text-truncate mb-0">
                    {{ response.risk.name }}
                  </p>
                </div>
              </td>

              <td aria-colindex="2" role="cell" style="width: 200px">
                <div
                  style="width: 200px"
                  class="d-flex justify-content-start"
                  v-b-tooltip.hover.top.v-secondary
                  :title="response.risk_statement"
                >
                  <p class="text-left text-truncate font-weight-bold mb-0">
                    {{ response.risk_statement }}
                  </p>
                </div>
              </td>
              <td aria-colindex="2" role="cell">
                <div class="d-flex justify-content-center">
                  <progress-bar
                    :value="response.risk_value"
                    :min="1"
                    :max="100"
                  />
                </div>
              </td>
              <!-- <td
                  aria-colindex="2"
                  role="cell"
                  style="padding-right: 0; width: 340px"
                >
                  <div
                    style="width: 340px"
                    class="d-flex justify-content-start"
                  >
                    <p class="text-left font-weight-bold mb-0">
                      {{ response.threat.name }}
                    </p>
                  </div>
                </td> -->
              <!-- <td role="cell" style="width: 250px">
                  <div
                    class="d-flex justify-content-center"
                    style="width: 250px"
                  >
                    <score-input
                      :id="response.threat_value.toString()"
                      :value="Number(response.threat_value)"
                      :disableChanges="true"
                    />
                  </div>
                </td> -->
              <!-- <td
                  aria-colindex="2"
                  role="cell"
                  style="padding-right: 0; width: 340px"
                >
                  <div
                    style="width: 340px"
                    class="d-flex justify-content-start"
                  >
                    <p class="text-left font-weight-bold mb-0">
                      {{ response.vulnerability.name }}
                    </p>
                  </div>
                </td> -->
              <!-- <td aria-colindex="2" role="cell" style="width: 250px">
                  <div
                    class="d-flex justify-content-center"
                    style="width: 250px"
                  >
                    <score-input
                      :id="response.vulnerability_value.toString()"
                      :value="Number(response.vulnerability_value)"
                      :disableChanges="true"
                    />
                  </div>
                </td> -->
              <!-- <td aria-colindex="2" role="cell" style="width: 250px">
                  <div
                    class="d-flex justify-content-center"
                    style="width: 250px"
                  >
                    <progress-bar
                      :value="response.impact_value"
                      :min="1"
                      :max="100"
                    />
                  </div>
                </td> -->
              <!-- <td
                  aria-colindex="2"
                  role="cell"
                  style="padding-right: 0; padding-left: 0; width: 250px"
                >
                  <div
                    style="width: 250px"
                    class="d-flex justify-content-center align-items-center"
                  >
                    <b-badge variant="light-primary">{{
                      getLikelihoodTextFromValue(response.likelihood_value)
                    }}</b-badge>
                  </div>
                </td> -->
              <td aria-colindex="2" role="cell">
                <div class="d-flex justify-content-center">
                  <progress-bar
                    :value="response.residual_risk_value"
                    :min="1"
                    :max="100"
                  />
                </div>
              </td>
              <!-- <td aria-colindex="2" role="cell" style="width: 250px">
                  <div
                    class="d-flex justify-content-center"
                    style="width: 250px"
                  >
                    <score-input
                      :id="response.residual_threat_value.toString()"
                      :value="Number(response.residual_threat_value)"
                      :disableChanges="true"
                    />
                  </div>
                </td> -->
              <!-- <td aria-colindex="2" role="cell" style="width: 250px">
                  <div
                    class="d-flex justify-content-center"
                    style="width: 250px"
                  >
                    <score-input
                      :id="response.residual_vulnerability_value.toString()"
                      :value="Number(response.residual_vulnerability_value)"
                      :disableChanges="true"
                    />
                  </div>
                </td> -->
              <!-- <td
                  aria-colindex="2"
                  role="cell"
                  style="padding-right: 0; width: 250px"
                >
                  <div
                    class="d-flex justify-content-center"
                    style="width: 250px"
                  >
                    <progress-bar
                      :value="response.residual_impact_value"
                      :min="1"
                      :max="100"
                    />
                  </div>
                </td> -->
              <!-- <td
                  aria-colindex="2"
                  role="cell"
                  style="padding-right: 0; padding-left: 0; width: 250px"
                >
                  <div
                    style="width: 250px"
                    class="d-flex justify-content-center align-items-center"
                  >
                    <b-badge variant="light-primary">{{
                      getLikelihoodTextFromValue(
                        response.residual_likelihood_value
                      )
                    }}</b-badge>
                  </div>
                </td> -->
              <td aria-colindex="2" role="cell">
                <div
                  class="d-flex flex-column justify-content-center align-items-center"
                >
                  <b-button
                    @click.stop="handleResponseClick(response)"
                    variant="primary"
                    style="width: 100px"
                    size="sm"
                  >
                    <feather-icon icon="EditIcon" class="mr-50" /> Edit
                  </b-button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- </div> -->
        <div class="pt-3">
          <div class="row">
            <div
              class="d-flex align-items-center justify-content-center col-12"
            >
              <b-pagination
                v-model="responseFilters.page"
                :per-page="pagination.perPage"
                :total-rows="pagination.rows"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResponseMixins from "../../mixins/ResponseMixins";
import RiskAssessmentMixins from "../../mixins/RiskAssessmentMixins";
import UtilsMixins from "../../mixins/UtilsMixins";
import ProgressBar from "@/components/ProgressBar.vue";
import ScoreInput from "@/components/ScoreInput.vue";
import {
  BFormSelect,
  BPagination,
  BButton,
  BSidebar,
  BBadge,
  VBTooltip,
} from "bootstrap-vue";
import RiskAsessmentForm from "./components/ResponseAssessmentForm.vue";
import ResponseAssessmentDetailsView from "./components/ResponseAssessmentDetailsView.vue";
import moment from 'moment';
import BackButton from "@/components/BackButton.vue"
export default {
  components: {
    ProgressBar,
    ScoreInput,
    BFormSelect,
    BPagination,
    BButton,
    BSidebar,
    RiskAsessmentForm,
    ResponseAssessmentDetailsView,
    BBadge,
    BackButton
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  filters: {
    moment: function (date) {
      return moment(date).format("D-MMM-YY");
    },
  },
  data() {
    return {
      assessment: null,
      responses: null,
      sidebarMode: "NEW",
      selectedResponse: null,
      responseFilters: {
        page: 1,
      },
      pagination: {
        perPage: 10,
        rows: 1,
      },
      likelihoodOptions: [
        { value: null, text: "Select a value.." },
        { value: 1, text: "Once 5 years" },
        { value: 2, text: "Once 2 years" },
        { value: 3, text: "Once a year" },
        { value: 4, text: "Once in Six Months" },
        { value: 5, text: "Once Month" },
      ],
      sidebar: {
        title: null,
        description: null,
      },
    };
  },

  mixins: [ResponseMixins, RiskAssessmentMixins, UtilsMixins],

  watch: {
    responseFilters: {
      handler: function (newValue) {
        console.log(newValue);
        this.getRiskAssessmentResponseAndSetData(
          this.$route.params.id,
          newValue
        );
      },
      deep: true,
    },
  },

  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.getRiskAssessmentDetailsAndSetData(this.$route.params.id);
      this.getRiskAssessmentResponseAndSetData(
        this.$route.params.id,
        this.responseFilters
      );
    },

    setSidebarViewMode(value) {
      this.sidebarMode = value;
      if (value === "EDIT") {
        this.sidebar.title = "Edit Risk";
        this.sidebar.description =
          "Make changes and click the Update button below to apply the changes.";
      }
    },

    handleAssessmentDetailsView(response) {
      console.log("Details Response", response);
      this.selectedResponse = response;
      this.sidebarMode = "VIEW";
      this.sidebar.title = response.risk.name;
      this.sidebar.description = "";
      this.openSidebar();
    },

    closeDetailsSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-assessment-details");
    },
    openDetailsSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-assessment-details");
      });
    },

    getLikelihoodTextFromValue(value) {
      if (value && value !== null) {
        if (value == 1) {
          return "Once in 5 years";
        } else if (value == 2) {
          return "Once in 2 years";
        } else if (value == 3) {
          return "Once a year";
        } else if (value == 4) {
          return "Once in Six months";
        } else if (value == 5) {
          return "Once a month";
        } else {
          console.log("Unknown Value", value);
          return "Unknown Value";
        }
      }
    },

    handleResponseClick(response) {
      this.selectedResponse = response;
      this.sidebarMode = "EDIT";
      this.sidebar.title = "Edit Risk";
      this.sidebar.description =
        "Make changes and click the Update button below to apply the changes.";
      this.openSidebar();
    },

    getRiskAssessmentDetailsAndSetData(id) {
      this.getRiskAssessment(id)
        .then((res) => {
          this.assessment = res.data.data;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        });
    },
    getRiskAssessmentResponseAndSetData(id, params) {
      this.getRiskAssessmentResponses(id, params)
        .then((res) => {
          this.responseFilters.page = res.data.data.current_page;
          this.pagination.perPage = res.data.data.per_page;
          this.pagination.rows =
            res.data.data.per_page * res.data.data.last_page;
          this.responses = res.data.data.data;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        });
    },

    handleCreateNewRiskClick() {
      this.sidebarMode = "NEW";
      this.selectedResponse = null;
      this.sidebar.title = "Add New Risk";
      this.sidebar.description =
        "Please complete the Form and click Submit to add new Risk.";

      this.openSidebar();
    },

    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },
    openSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
      });
    },
  },
};
</script>

<!-- <style lang="css" scoped>
.b-sidebar.sidebar-lg {
  width: 60rem !important;
}
</style> -->

<style lang="css" scoped>
.header {
  position: sticky;
  top: 0;
  z-index: 99;
}
/* td:first-child
{
  position:sticky;
  left:0px;
  z-index: 90;
} */
</style>