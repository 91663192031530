<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <div class="card" style="box-shadow: none !important">
      <div class="card-header border-bottom d-flex">
        <div
          style="width: 70%"
          class="d-flex flex-column justify-content-center align-items-start"
        >
          <template v-if="componentMode == 'EDIT'">
            <h4 class="font-weight-bolder">
              <span class="align-middle">{{ headerData.title }}</span>
            </h4>
            <p class="card-subtitle text-muted pt-75">
              {{ headerData.description }}
            </p>
          </template>
          <template>
            <h4 v-if="componentMode !== 'EDIT'" class="font-weight-bolder">
              <span class="align-middle">Risk Details</span>
            </h4>
          </template>
        </div>

        <div class="d-flex justify-content-end align-items-center">
          <b-button
            size="sm"
            @click="closeSidebar()"
            variant="outline-secondary"
            ><feather-icon size="24" icon="XIcon"
          /></b-button>
        </div>
      </div>
      <div v-if="componentMode == 'VIEW'" class="w-100 p-1 pb-0">
        <b-button
          size="sm"
          variant="primary"
          @click="handleEditButtonClick()"
          class="float-right"
        >
          <feather-icon icon="EditIcon" class="mr-50" />
          <span class="align-middle">Edit</span>
        </b-button>
      </div>
      <div class="card-body pt-3" v-if="componentMode != 'VIEW'">
        <validation-observer ref="risk_assessment_form" #default="{ invalid }">
          <b-form
            class="mt-2"
            @submit.prevent="
              () => {
                componentMode == 'EDIT'
                  ? handleResponseUpdateClick()
                  : handleSubmitClick();
              }
            "
          >
            <b-form-group label="Risk">
              <template slot="label">
                Risk <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                vid="risk_name"
                name="Risk"
                :rules="{
                  required: true,
                }"
              >
                <vue-autosuggest
                  id="autosuggest__input"
                  :input-props="{
                    class: 'form-control',
                    placeholder: 'Risk Name...',
                  }"
                  :suggestions="riskSuggestions"
                  :limit="5"
                  v-model="formData.risk.name"
                  @input="getRisksAndSetSuggestions"
                  @selected="onRiskSelected"
                  :get-suggestion-value="getRiskSuggestionValue"
                >
                  <template slot-scope="{ suggestion }">
                    <span style="{ display: 'flex', color: 'navyblue'}"
                      >{{ suggestion.item.name }}
                    </span>
                  </template>
                </vue-autosuggest>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Threat"
              v-if="
                formData.risk.selected === null ||
                formData.risk.selected.added_by == $store.state.app.user.user_id
              "
            >
              <template slot="label">
                Threat <span class="text-danger">*</span>
              </template>
              <div
                class="d-flex flex-row justify-content-between align-items-center"
              >
                <validation-provider
                  #default="{ errors }"
                  vid="threat_id"
                  name="Threat"
                  :rules="{
                    required: true,
                  }"
                  style="width: 60%"
                >
                  <vue-autosuggest
                    id="autosuggest__input"
                    :input-props="{
                      class: 'form-control',
                      placeholder: 'Threat Name...',
                    }"
                    :suggestions="threatSuggestions"
                    :limit="5"
                    v-model="formData.threat.name"
                    @input="getThreatsAndSetSuggestions"
                    @selected="onThreatSelected"
                    :get-suggestion-value="getThreatSuggestionValue"
                  >
                    <template slot-scope="{ suggestion }">
                      <span style="{ display: 'flex', color: 'navyblue'}"
                        >{{ suggestion.item.name }}
                      </span>
                    </template>
                  </vue-autosuggest>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

                <div
                  style="width: 36%"
                  class="d-flex align-items-center justify-content-center"
                >
                  <validation-provider
                    #default="{ errors }"
                    vid="threat_value"
                    name="Threat Value"
                    class="w-100"
                    :rules="{
                      required: formData.threat.name !== null ? true : false,
                    }"
                  >
                    <b-form-select
                      v-model="formData.threat.value"
                      :options="vulnerabilityOptions"
                    ></b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <!-- <p class="mb-0 font-weight-bolder">Threat Value</p>
                    <vue-slider
                      v-model="formData.threat.value"
                      :disabled="formData.threat.name === null ? true : false"
                      :min="1"
                      :max="25"
                      :width="250"
                      direction="ltr"
                      :tooltip="'always'"
                      :process="false"
                      :dot-style="{ backgroundColor: '#7267f0' }"
                    /> -->
                </div>
              </div>
            </b-form-group>

            <b-form-group
              label="Vulnerabilities"
              v-if="
                formData.risk.selected === null ||
                formData.risk.selected.added_by == $store.state.app.user.user_id
              "
            >
              <template slot="label">
                Vulnerabilities <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Vulnerabilities"
                vid="vulnerabilities"
                :rules="{
                  required: true,
                }"
              >
                <div class="card border w-100" style="min-height: 230px">
                  <div
                    v-if="formData.vulnerabilities.addedList.length <= 0"
                    class="card-body d-flex flex-column align-items-start justify-content-around"
                  >
                    <div
                      class="d-flex justify-content-center align-items-center w-100"
                      style="height: 150px"
                    >
                      <p class="mb-0 text-center">
                        There are no vulnerabilities, Please add
                        Vulnerability<span class="text-danger">*</span>
                      </p>
                    </div>
                  </div>

                  <div
                    v-else
                    class="card-body d-flex flex-column align-items-center justify-content-start px-3 py-1"
                    style="min-height: 200px"
                  >
                    <div
                      :key="i"
                      v-for="(vulnerability, i) in formData.vulnerabilities
                        .addedList"
                      class="w-100 d-flex align-items-center justify-content-between my-75"
                    >
                      <!-- <div class="card-header">
                        <div
                          class="
                            d-flex
                            flex-row
                            align-items-center
                            justify-content-end
                          "
                        >
                          <b-button
                            variant="danger"
                            class="btn-icon rounded-circle"
                            ><feather-icon class="text-white" icon="XIcon" />
                          </b-button>
                        </div>
                      </div>
                      <div class="card-body">
                        <p
                          class="
                            text-primary text-center
                            font-weight-bolder
                            mb-0
                          "
                        >
                          {{ vulnerability.name }}
                        </p>
                      </div>
                      <div class="card-footer">
                        <b-form-select
                          v-model="vulnerability.value"
                          :options="vulnerabilityOptions"
                        ></b-form-select>
                      </div> -->

                      <div
                        class="d-flex align-items-center justify-content-center"
                        style="width: 40%"
                      >
                        <vue-autosuggest
                          style="width: 100%"
                          id="autosuggest__input"
                          :input-props="{
                            class: 'form-control',
                            placeholder: 'Search & Select Vulnerabilities...',
                          }"
                          :suggestions="vulnerabilitySuggestions"
                          :limit="5"
                          v-model="vulnerability.name"
                          @input="getVulnerabilitiesAndSetSuggestions"
                          @selected="
                            (vul) => {
                              onVulnerabilitySelected(vul, i);
                            }
                          "
                          :get-suggestion-value="
                            getVulnerabilitySuggestionValue
                          "
                        >
                          <template slot-scope="{ suggestion }">
                            <span style="{ display: 'flex', color: 'navyblue'}"
                              >{{ suggestion.item.name }}
                            </span>
                          </template>
                        </vue-autosuggest>
                      </div>
                      <div
                        class="d-flex align-items-center justify-content-center"
                        style="width: 40%"
                      >
                        <b-form-select
                          v-model="vulnerability.value"
                          :options="vulnerabilityOptions"
                        ></b-form-select>
                      </div>
                      <div
                        class="d-flex align-items-center justify-content-center"
                      >
                        <b-button
                          variant="danger"
                          class="btn-icon rounded-circle"
                          @click="removeSelectedVulnerability(i)"
                          ><feather-icon class="text-white" icon="XIcon" />
                        </b-button>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card-footer bg-light-secondary d-flex align-items-center justify-content-start"
                  >
                    <b-button
                      variant="primary"
                      @click="onAddVulnerabilityClick()"
                      size="sm"
                      ><feather-icon icon="PlusIcon" class="mr-50" />
                      <span class="align-middle">Add New</span></b-button
                    >
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Statement">
              <template slot="label">
                Statement <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                vid="risk_statement"
                name="Risk Statement"
                :rules="{
                  required: false,
                }"
              >
                <b-form-textarea
                  v-model="formData.riskStatement"
                  id="risk_statement"
                  :state="errors.length > 0 ? false : null"
                  rows="3"
                  max-rows="4"
                ></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Impact Score">
              <template slot="label">
                Impact Score <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                vid="impact_score"
                name="Impact Score"
                :rules="{
                  required: true,
                  positive: true,
                }"
              >
                <!-- between: { min: 1, max: 100 }, -->
                <!-- <range-slider
                  v-model="formData.impactValue"
                  :min="1"
                  :max="100"
                 
                /> -->
                <b-form-select
                  v-model="formData.impactValue"
                  :options="impactOptions"
                  @change="handleImpactSelect"
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Likelihood">
              <template slot="label">
                Likelihood <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                vid="likelihood_value"
                name="Likelihood"
                :rules="{
                  required: true,
                }"
              >
                <b-form-select
                  v-model="formData.likelihoodValue"
                  :options="likelihoodOptions"
                  @change="handleLikelihoodSelect"
                ></b-form-select>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Residual Impact Score">
              <template slot="label">
                Residual Impact Score <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                vid="residual_impact_value"
                name="Residual Impact Score"
                :rules="{
                  required: true,
                }"
              >
                <!-- <range-slider
                  v-model="formData.residualImpactValue"
                  :min="1"
                  :max="100"
                 
                /> -->

                <b-form-select
                  v-model="formData.residualImpactValue"
                  :options="impactOptions"
                ></b-form-select>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Residual Likelihood">
              <template slot="label">
                Residual Likelihood <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                vid="residual_likelihood_value"
                name="Residual Likelihood"
                :rules="{
                  required: true,
                }"
              >
                <b-form-select
                  v-model="formData.residualLikelihoodValue"
                  :options="likelihoodOptions"
                ></b-form-select>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-button
              v-if="componentMode == 'NEW'"
              type="submit"
              variant="primary"
              class="mr-1 mt-1"
              :disabled="invalid"
            >
              <feather-icon icon="CheckIcon" class="mr-50" />
              <span class="align-middle">Submit</span>
            </b-button>

            <b-button
              v-else
              variant="success"
              type="submit"
              class="mr-1 mt-1"
              :disabled="invalid"
            >
              <feather-icon icon="CheckIcon" class="mr-50" />
              <span class="align-middle">Update</span>
            </b-button>
          </b-form>
        </validation-observer>
      </div>
      <response-assessment-details-view
        v-else
        :closeSidebar="closeSidebar"
        :response="prefetchedFormData"
        :assessmentId="riskAssessmentId"
      />
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BBadge,
  BFormSelect,
  BFormTextarea,
} from "bootstrap-vue";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { VueAutosuggest } from "vue-autosuggest";
import RiskMixins from "../../../mixins/RiskMixins";
import ResponseMixins from "../../../mixins/ResponseMixins";
import UtilsMixins from "../../../mixins/UtilsMixins";
import RiskAssessmentMixins from "../../../mixins/RiskAssessmentMixins";
import RangeSlider from "../../../components/RangeSlider.vue";
import ResponseAssessmentDetailsView from "./ResponseAssessmentDetailsView.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BOverlay,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    required,
    VueAutosuggest,
    BForm,
    VueSlider,
    BBadge,
    RangeSlider,
    BFormTextarea,
    ResponseAssessmentDetailsView,
  },
  data() {
    return {
      showOverlay: false,
      disableEditableFields: false,
      impactOptions: [
        { value: null, text: "Select a value" },
        { value: 1, text: "Very Low" },
        { value: 2, text: "Low" },
        { value: 3, text: "Medium" },
        { value: 4, text: "High" },
        { value: 5, text: "Critical" },
      ],
      vulnerabilityOptions: [
        { value: null, text: "Select a value" },
        { value: 1, text: "Very Low" },
        { value: 2, text: "Low" },
        { value: 3, text: "Medium" },
        { value: 4, text: "High" },
        { value: 5, text: "Critical" },
      ],
      likelihoodOptions: [
        { value: null, text: "Select a value.." },
        { value: 1, text: "Once 5 years" },
        { value: 2, text: "Once 2 years" },
        { value: 3, text: "Once a year" },
        { value: 4, text: "Once in Six Months" },
        { value: 5, text: "Once Month" },
      ],

      riskSuggestions: [],
      threatSuggestions: [],
      vulnerabilitySuggestions: [],
      selectedRiskCreatedUser: null,
      formData: {
        risk: {
          selected: null,
          name: null,
          id: null,
        },
        threat: {
          selected: null,
          name: null,
          id: null,
          value: 1,
        },
        vulnerabilities: {
          selected: null,
          text: null,
          addedList: [],
        },
        riskStatement: null,
        impactValue: null,
        likelihoodValue: null,
        residualImpactValue: null,
        residualLikelihoodValue: null,
      },
    };
  },

  mixins: [RiskMixins, ResponseMixins, UtilsMixins, RiskAssessmentMixins],

  props: {
    closeSidebar: {
      required: true,
      type: Function,
    },
    componentMode: {
      required: true,
      type: String,
    },
    setComponentMode: {
      required: true,
      type: Function,
    },
    prefetchedFormData: {
      required: false,
      type: Object,
    },
    headerData: {
      type: Object,
      required: true,
    },
    riskAssessmentId: {
      type: String,
      required: false,
    },
    response: {
      type: Object,
      required: false,
    },
    reloadParent: {
      type: Function,
      required: true,
    },
  },

  watch: {
    componentMode(newValue) {
      if (newValue == "EDIT") {
        this.load();
      }
    },
  },

  mounted() {
    if (this.componentMode === "EDIT") {
      this.load();
    } else {
      this.formData = {
        risk: {
          selected: null,
          name: null,
          id: null,
        },
        threat: {
          selected: null,
          name: null,
          id: null,
          value: null,
        },
        vulnerabilities: {
          selected: null,
          text: null,
          addedList: [],
        },
        riskStatement: null,
        impactValue: null,
        likelihoodValue: null,
        residualImpactValue: null,
        residualLikelihoodValue: null,
      };
    }

    // this.fillFormDataWithPredefinedData();
    // this.disableEditableFields = false;
  },

  methods: {
    load() {
      this.getRiskAssessmentResponseDetailsAndSetData(
        this.riskAssessmentId,
        this.response._id
      );
    },

    checkIfRiskOfAnotherUser() {
      if (this.selectedRiskCreatedUser == null) {
        return false;
      } else if (
        this.selectedRiskCreatedUser &&
        this.selectedRiskCreatedUser != null &&
        this.selectedRiskCreatedUser == this.$store.state.app.user.user_id
      ) {
        return false;
      } else if (
        this.selectedRiskCreatedUser &&
        this.selectedRiskCreatedUser != null &&
        this.selectedRiskCreatedUser != this.$store.state.app.user.user_id
      ) {
        return true;
      } else {
        return false;
      }
    },

    getRiskAssessmentResponseDetailsAndSetData(assessmentId, responseId) {
      this.showOverlay = true;
      this.getRiskAssessmentResponseDetails(assessmentId, responseId)
        .then((res) => {
          console.log("ResponseDetails", res);
          const responseDetails = res.data.data;
          this.fillFormDataWithPredefinedData(responseDetails);
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleImpactSelect(value) {
      if (this.formData.residualImpactValue == null) {
        this.formData.residualImpactValue = value;
      }
    },
    handleLikelihoodSelect(value) {
      if (this.formData.residualLikelihoodValue == null) {
        this.formData.residualLikelihoodValue = value;
      }
    },
    mapColorToValue(value) {
      if (value <= 11) {
        return "#28C76F";
      } else if (value > 11 && value <= 17) {
        return "#00CFE8";
      } else if (value > 17 && value <= 35) {
        return "#7267f0";
      } else if (value > 35 && value <= 74) {
        return "#FF9F43";
      } else if (value > 74 && value <= 100) {
        return "#EA5455";
      } else {
        return "#28C76F";
      }
    },

    mapTextClassToValue(value) {
      if (value <= 11) {
        return "text-success";
      } else if (value > 11 && value <= 17) {
        return "text-info";
      } else if (value > 17 && value <= 35) {
        return "text-primary";
      } else if (value > 35 && value <= 74) {
        return "text-warning";
      } else if (value > 74 && value <= 100) {
        return "text-danger";
      } else {
        return "text-success";
      }
    },

    mapValueName(value) {
      if (value <= 11) {
        return "Very Low";
      } else if (value > 11 && value <= 17) {
        return "Low";
      } else if (value > 17 && value <= 35) {
        return "Medium";
      } else if (value > 35 && value <= 74) {
        return "High";
      } else if (value > 74 && value <= 100) {
        return "Very High";
      } else {
        return "Very Low";
      }
    },

    handleSubmitClick() {
      this.storeRiskAssessmentResponse(this.$route.params.id, this.formData)
        .then((res) => {
          this.handleResponse(res);
          this.reloadParent();
          this.closeSidebar();
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
          if (err.response.status === 422) {
            this.$refs.risk_assessment_form.setErrors(err.response.data.errors);
          }
        });
    },

    handleEditButtonClick() {
      this.setComponentMode("EDIT");
    },

    handleResponseUpdateClick() {
      this.updateRiskAssessmentResponse(
        this.$route.params.id,
        this.prefetchedFormData._id,
        this.formData
      )
        .then((res) => {
          this.handleResponse(res);
          this.reloadParent();
          this.closeSidebar();
        })
        .catch((err) => {
          // this.handleError(err);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data.errors.vulnerabilities[0],
              icon: "EditIcon",
              variant: "danger",
            },
          });
          console.log(err);
        });
    },

    fillFormDataWithPredefinedData(data) {
      //--------Risk-----------
      this.formData.risk.id = data.risk._id;
      this.formData.risk.name = data.risk.name;
      this.formData.risk.selected = data.risk;
      //--------Threat-----------
      if (data.risk.threat && data.risk.threat !== null) {
        this.formData.threat.id = data.risk.threat._id;
        this.formData.threat.name = data.risk.threat.name;
        this.formData.threat.selected = data.risk.threat;
        this.formData.threat.value = Number(data.risk.threat.threat_value);
      }

      //--------Vulnerabilities-----------
      if (data.risk.vulnerabilities && data.risk.vulnerabilities !== null) {
        data.risk.vulnerabilities.map((vulnerability) => {
          this.formData.vulnerabilities.addedList.push({
            name: vulnerability.name,
            selected: vulnerability,
            id: vulnerability._id,
            value: Number(vulnerability.vulnerability_value),
          });
        });
      }

      //---------Risk Statement--------------
      this.formData.riskStatement = data.risk_statement;
      //---------Impact Value--------------
      this.formData.impactValue = Number(data.impact_score);
      //---------Residual Impact Value--------------
      this.formData.residualImpactValue = Number(data.residual_impact_score);
      //---------Likelihood Value--------------
      this.formData.likelihoodValue = data.likelihood_value;
      //---------Residual Likelihood Value--------------
      this.formData.residualLikelihoodValue = data.residual_likelihood_value;
    },

    //-------------------Risk Section------------------------
    onRiskSelected(risk) {
      this.resetRiskPreExistsPrefilledData();
      this.formData.risk.selected = risk.item;
      this.formData.risk.name = risk.item.name;
      this.formData.threat.id = risk.item._id;
      // If The Selected Risk Contains Threats & Vulnerabilities prefill the input fields
      if (
        (risk.item.threat && risk.item.threat != null) ||
        (risk.item.added_by && risk.item.added_by != null)
      ) {
        this.handleRiskPreExists(risk.item);
      }
    },
    getRiskSuggestionValue(suggestion) {
      return suggestion.item.name;
    },
    getRisksAndSetSuggestions(searchPhrase) {
      if (searchPhrase !== null && searchPhrase !== "") {
        this.debounceFunction(() => {
          this.getAllRisksBySearchPhrase(searchPhrase)
            .then((res) => {
              if (res.data.data.length > 0) {
                this.riskSuggestions = [];
                let risks = res.data.data;
                this.riskSuggestions.push({
                  name: "risks",
                  data: risks,
                });
              } else {
                this.riskSuggestions = [];
              }
            })
            .catch((err) => {
              this.handleError(err);
              console.log(err);
            });
        });
      }
    },

    handleRiskPreExists(data) {
      // Storing Id Of the Risk Creator
      if (
        data.added_by &&
        data.added_by != null &&
        typeof data.added_by != "undefined"
      ) {
        this.selectedRiskCreatedUser = data.added_by;
      }

      //Prefill Threat Fields
      this.formData.threat.selected = data.threat;
      this.formData.threat.name = data.threat.name;
      this.formData.threat.id = data.threat._id;
      this.formData.threat.value = Number(data.threat.threat_value);

      //Prefill Vulnerability Fields
      if (
        data.vulnerabilities &&
        data.vulnerabilities != null &&
        data.vulnerabilities.length > 0
      ) {
        data.vulnerabilities.map((vulnerability) => {
          this.formData.vulnerabilities.addedList.push({
            name: vulnerability.name,
            selected: vulnerability,
            id: vulnerability._id,
            value: Number(vulnerability.vulnerability_value),
          });
        });
      }
    },

    resetRiskPreExistsPrefilledData() {
      let newFormData = this.formData;
      newFormData.threat = {
        selected: null,
        name: null,
        id: null,
        value: null,
      };
      newFormData.vulnerabilities = {
        selected: null,
        text: null,
        addedList: [],
      };
      (newFormData.risk = {
        selected: null,
        name: null,
        id: null,
      }),
        (this.formData = newFormData);
    },

    //-------------------Threat Section------------------------
    onThreatSelected(threat) {
      this.formData.threat.selected = threat.item;
      this.formData.threat.name = threat.item.name;
      this.formData.threat.id = threat.item._id;
    },
    getThreatSuggestionValue(suggestion) {
      return suggestion.item.name;
    },
    getThreatsAndSetSuggestions(searchPhrase) {
      if (searchPhrase !== null && searchPhrase !== "") {
        this.debounceFunction(() => {
          this.getAllThreatsBySearchPhrase(searchPhrase)
            .then((res) => {
              console.log("Threats", res.data.data);
              if (res.data.data.length > 0) {
                this.threatSuggestions = [];
                let threats = res.data.data;
                console.log("Threats", threats);
                this.threatSuggestions.push({
                  name: "threats",
                  data: threats,
                });
              } else {
                this.threatSuggestions = [];
              }
            })
            .catch((err) => {
              this.handleError(err);
              console.log(err);
            });
        });
      }
    },

    //-------------------Vulnerability Section------------------------
    onVulnerabilitySelected(vulnerability, listIndex) {
      console.log("Vulnerability Selected:", vulnerability);
      let newVulnerability = this.formData.vulnerabilities.addedList[listIndex];
      newVulnerability.name = vulnerability.item.name;
      newVulnerability.selected = vulnerability.item;
      newVulnerability.id = vulnerability.item._id;
      this.formData.vulnerabilities.addedList[listIndex] = newVulnerability;
      console.log("Added", this.formData.vulnerabilities.addedList[listIndex]);
    },
    getVulnerabilitySuggestionValue(suggestion) {
      return suggestion.item.name;
    },
    getVulnerabilitiesAndSetSuggestions(searchPhrase) {
      if (searchPhrase !== null && searchPhrase !== "") {
        this.debounceFunction(() => {
          this.getAllVulnerabilitiesBySearchPhrase(searchPhrase)
            .then((res) => {
              console.log(res.data.data);
              if (res.data.data.length > 0) {
                this.vulnerabilitySuggestions = [];
                this.vulnerabilitySuggestions.push({
                  name: "vulnerabilities",
                  data: res.data.data,
                });
              } else {
                this.vulnerabilitySuggestions = [];
              }
            })
            .catch((err) => {
              this.handleError(err);
              console.log(err);
            });
        });
      }
    },
    removeSelectedVulnerability(index) {
      const newVuls = this.formData.vulnerabilities.addedList.filter(
        (vul, i) => i !== index
      );
      this.formData.vulnerabilities.addedList = newVuls;
    },
    onAddVulnerabilityClick() {
      this.formData.vulnerabilities.addedList.push({
        name: null,
        value: null,
        selected: null,
        id: null,
      });
    },
    // onAddVulnerabilityClick() {
    //   let newSelected = this.formData.vulnerabilities.selected;
    //   newSelected["value"] = 1;
    //   this.formData.vulnerabilities.selected = newSelected;
    //   if (this.formData.vulnerabilities.addedList.length > 0) {
    //     const vulnerabilityIndex =
    //       this.formData.vulnerabilities.addedList.findIndex(
    //         (vul) => vul._id === this.formData.vulnerabilities.selected._id
    //       );
    //     if (vulnerabilityIndex === -1) {
    //       this.formData.vulnerabilities.addedList.push(
    //         this.formData.vulnerabilities.selected
    //       );
    //     }
    //   } else {
    //     this.formData.vulnerabilities.addedList.push(
    //       this.formData.vulnerabilities.selected
    //     );
    //   }

    //   this.formData.vulnerabilities.text = null;
    //   this.formData.vulnerabilities.selected = null;
    // },
  },
};
</script>

<style></style>
